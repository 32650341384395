import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ComplianceCategoryDTO, ComplianceCategoryFormDTO } from "../dto/compliance-category.dto";

@Injectable({
  providedIn: 'root'
})
export class ComplianceCategoryService {

  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

  getComplianceCategories(
      sortField: string = 'title_en',
      sortOrder: string = 'asc',
      searchQuery?: string
  ): Observable<ComplianceCategoryDTO[]> {
      let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder);

      if (searchQuery) {
          params = params.set('keyword', searchQuery);
      }

      return this.http.get<ComplianceCategoryDTO[]>(this.apiUrl + '/compliance-categories', {
          params,
      });
  }

  getComplianceCategory(id: number): Observable<ComplianceCategoryDTO> {
      return this.http.get<ComplianceCategoryDTO>(`${this.apiUrl}/compliance-categories/${id}`);
  }

  addComplianceCategory(
      complianceCategory: ComplianceCategoryFormDTO
  ): Observable<ComplianceCategoryFormDTO> {
      return this.http.post<ComplianceCategoryFormDTO>(
      this.apiUrl + '/compliance-categories',
      complianceCategory
      );
  }
  
    updateComplianceCategory(
      id: number,
      complianceCategory: ComplianceCategoryFormDTO
    ): Observable<ComplianceCategoryFormDTO> {
      return this.http.put<ComplianceCategoryFormDTO>(
        `${this.apiUrl}/compliance-categories/${id}`,
        complianceCategory
      );
    }
  
    activateComplianceCategories(ids: number[]): Observable<any> {
      return this.http.put(`${this.apiUrl}/compliance-categories/activate`, { ids: ids });
    }
  
    deactivateComplianceCategories(ids: number[]): Observable<any> {
      return this.http.put(`${this.apiUrl}/compliance-categories/deactivate`, { ids: ids });
    }
  
    deleteComplianceCategories(ids: number[]): Observable<any> {
      return this.http.post(`${this.apiUrl}/compliance-categories/delete`, { ids: ids });
    }
}
