import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ComplianceReviewStatusDTO } from "../dto/compliance-review-status.dto";  // Adjusted DTO import

@Injectable({
  providedIn: 'root',
})
export class ComplianceReviewStatusService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getComplianceReviewStatuses(
        sortField: string = 'title_en',
        sortOrder: string = 'asc',
        searchQuery?: string
    ): Observable<ComplianceReviewStatusDTO[]> {
        let params = new HttpParams()
        .set('sort_by', sortField)
        .set('sort_order', sortOrder);

        if (searchQuery) {
        params = params.set('keyword', searchQuery);
        }

        return this.http.get<ComplianceReviewStatusDTO[]>(this.apiUrl + '/compliance-review-statuses', {
        params,
        });
    }
}
